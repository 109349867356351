export class Action {
    static readonly VALIDATE = 'VAL';
    static readonly FINALIZE = 'FIN';
    static readonly APPROVAL = 'APP';
    static readonly MODIFY = 'MOD';
    static readonly DELETE = 'DEL';
    static readonly CANCEL = 'CAN';
    static readonly CONFIRM = 'CON';
    static readonly BINDING = 'BIN';
    static readonly REVERSE = 'REV';
    static readonly CALCULATE = 'CAL';
    static readonly SEND_FOR_APPROVAL = 'STA';
    static readonly PLACEMENT = 'PLA';
    static readonly DIVIDER = 'divider';
    static readonly RUN = 'RUN';
    static readonly ACTIVATED = 'ACT';
    static readonly TERMINATED = 'TRM';
    static readonly RESET_PASS = 'RST';
    static readonly LOCK = 'LOC';
    static readonly UNLOCK = 'UNL';
    static readonly POSTING = 'POS';
    static readonly UNPOSTING = 'UNP';
    static readonly EXTRACT = 'EXT';
    static readonly BIND = 'BND';
    static readonly NEW = 'NEW';
    static readonly ADD_SUB_TREATY = 'ADD_SUB_TREATY';
    static readonly CORRECTION = 'COR';
    static readonly CONVERT_TO_PROPOSAL = 'PRO';
    static readonly DOWNLOAD = 'DOWNLOAD';
    static readonly PREVIEW = 'PREVIEW';
    static readonly SEND = 'SND';
    static readonly ISSUE = 'ISS';
    static readonly REACTIVATE = 'RAC';
}

export function getActionNew(disabled = false) {
    return {
        value: Action.NEW,
        text: 'New',
        icon: 'add',
        disabled,
    }
}

export function getActionSend(disabled = false) {
    return {
        value: Action.SEND,
        text: 'Send to Ceding',
        icon: 'swap_horiz',
        disabled,
    }
}

export function getActionAddSubTreaty(disabled = false) {
    return {
        value: Action.ADD_SUB_TREATY,
        text: 'Add Sub Treaty',
        icon: 'add',
        disabled,
    }
}

export function getActionCorrection(disabled = false) {
    return {
        value: Action.CORRECTION,
        text: 'Correction',
        icon: 'list',
        disabled,
    }
}

export function getActionConvertToProposal(disabled = false) {
    return {
        value: Action.CONVERT_TO_PROPOSAL,
        text: 'Convert to Proposal',
        icon: 'published_with_changes',
        disabled,
    }
}
export function getActionBind(disabled = false) {
    return {
        value: Action.BIND,
        text: 'Bind',
        icon: 'done',
        disabled,
    }
}

export function getActionPosting(disabled = false) {
    return {
        value: Action.POSTING,
        text: 'Posting',
        icon: 'redo',
        disabled,
    }
}

export function getActionExtract(disabled = false) {
    return {
        value: Action.EXTRACT,
        text: 'Extract',
        icon: 'functions',
        disabled,
    }
}

export function getActionUnposting(disabled = false) {
    return {
        value: Action.UNPOSTING,
        text: 'UnPosting',
        icon: 'undo',
        disabled,
    }
}

export function getActionRun(disabled = false) {
    return {
        value: Action.RUN,
        text: 'Run',
        icon: 'done',
        disabled,
    }
}


export function getActionValidate(disabled = false) {
    return {
        value: Action.VALIDATE,
        text: 'Validate',
        icon: 'done',
        disabled,
    }
}

export function getActionIssue(disabled = false) {
    return {
        value: Action.ISSUE,
        text: 'Issue',
        icon: 'done',
        disabled,
    }
}

export function getActionReactivate(disabled = false) {
    return {
        value: Action.REACTIVATE,
        text: 'Reactivate',
        icon: 'done',
        disabled,
    }
}

export function getActionDownload(disabled = false) {
    return {
        value: Action.DOWNLOAD,
        text: 'Preview',
        icon: 'download',
        disabled,
    }
}

export function getActionFinalize(disabled = false) {
    return {
        value: Action.FINALIZE,
        text: 'Finalize',
        icon: 'timeline',
        disabled,
    }
}

export function getActionSendForApproval(disabled = false, text = 'Send for Approval') {
    return {
        value: Action.SEND_FOR_APPROVAL,
        text,
        icon: 'send',
        disabled,
    }
}

export function getActionPlacement(disabled = false, text = 'Placement') {
    return {
        value: Action.PLACEMENT,
        text,
        icon: 'swap_horiz',
        disabled,
    }
}

export function getActionCalculate(disabled = false) {
    return {
        value: Action.CALCULATE,
        text: 'Calculate',
        icon: 'calculate',
        disabled,
    }
}

export function getActionConfirm(disabled = false) {
    return {
        value: Action.CONFIRM,
        text: 'Confirmation',
        icon: 'star',
        disabled,
    }
}

export function getActionBinding(disabled = false) {
    return {
        value: Action.BINDING,
        text: 'Binding',
        icon: 'star',
        disabled,
    }
}

export function getActionReverse(disabled = false) {
    return {
        value: Action.REVERSE,
        text: 'Reverse',
        icon: 'undo',
        disabled,
    }
}

export function getActionDelete(disabled = false) {
    return {
        value: Action.DELETE,
        text: 'Delete',
        icon: 'delete',
        disabled,
    }
}

export function getActionCancel(disabled = false) {
    return {
        value: Action.CANCEL,
        text: 'Cancel',
        icon: 'close',
        disabled,
    }
}

export function getActionApproval(disabled = false) {
    return {
        value: Action.APPROVAL,
        text: 'Approval',
        icon: 'done_all',
        disabled,
    }
}

export function getActionDivider() {
    return {
        value: Action.VALIDATE,
        text: 'Validate',
        icon: 'done',
        disabled: true,
    }
}

export function getActionModify(disabled = false) {
    return {
        value: Action.MODIFY,
        text: 'Modify',
        icon: 'edit',
        disabled,
    }
}

export function getActionActivated(disabled = false) {
    return {
        value: Action.ACTIVATED,
        text: 'Activate',
        icon: 'done',
        disabled,
    }
}

export function getActionTerminated(disabled = false) {
    return {
        value: Action.TERMINATED,
        text: 'Terminate',
        icon: 'close',
        disabled,
    }
}

export function getActionResetPassword(disabled = false) {
    return {
        value: Action.RESET_PASS,
        text: 'Reset Password',
        icon: 'settings_backup_restore',
        disabled,
    }
}

export function getActionLock(disabled = false) {
    return {
        value: Action.LOCK,
        text: 'Lock',
        icon: 'lock',
        disabled,
    }
}

export function getActionUnlock(disabled = false) {
    return {
        value: Action.UNLOCK,
        text: 'Unlock',
        icon: 'done',
        disabled,
    }
}
